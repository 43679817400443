<!-- 
 * @description: 我的订单
 * @fileName: my-order.vue
!-->
<template>
    <div class="my-order">
        <!-- <div class="tab">
            <span :class="{ active: currentTab === -1 }" @click="handleClickTab(-1)">全部订单</span>
            <span :class="{ active: currentTab === 0 }" @click="handleClickTab(0)">待付款</span>
            <span :class="{ active: currentTab === 2 }" @click="handleClickTab(2)">已完成</span>
            <span :class="{ active: currentTab === 9 }" @click="handleClickTab(9)">已关闭</span>
        </div> -->
        <!-- 培训订单 -->
        <template>
            <ul class="orderul" v-if="orderlist && orderlist.length">
                <li class="orderli" v-for="(order, index) in orderlist" :key="index">
                    <div class="bottom-order-status">
                        <img :src="ConstData.orderStatus[+order.status].picUrl" v-if="order.status>0 || (order.status==0&&order.left_time)">
                    </div>
                    <div class="ordertop">
                        <span class="number">订单编号：{{ order.orderNo }}</span>
                        <span class="ordertime">下单时间：{{ order.orderTime }}</span>
                        <span v-if="order.payWayName" class="ordertime">支付方式：{{ order.payWayName }}</span>
                    </div>
                    <div class="orderCurseInfo">
                        <div class="orderCurseLeft">
                            <img class="orderCourseImg" :src="order.goodsDto.coverImage" alt="" @click="goDetails(order)" />
                            <wd-tag :item="{type:order.orderType}"></wd-tag>
                        </div>
                        <div class="orderCurseRight">
                            <h3 @click="goDetails(order)">{{ order.orderName }}</h3>
                            <div class="bottom-order">
                                <p>
                                    <span class="price_key">实付金额：</span>
                                    <span class="price_val">￥{{ order.realPay }}</span>
                                </p>
                                <div class="bottom-order-right" v-if="order.status == 0 && order.left_time">
                                    <div class="btn_pay">
                                        <p class="daoji">
                                            请在<span class="time"> {{ order.left_time }} </span
                                            >内完成支付
                                        </p>
                                        <button
                                            @click="goPayment(order)"
                                            class="payBtn itemBtn"
                                        >
                                            马上支付
                                        </button>
                                        <button
                                            @click="cancelPayment(order)"
                                            class="payBtn itemBtn cancel-pay-btn"
                                        >
                                            取消订单
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="pagenation-wraper" v-show="orderlist && orderlist.length">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNum"
                    :page-sizes="[10, 20, 30]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total"
                >
                </el-pagination>
            </div>

            <div class="nodata-tip" v-if="orderlist && orderlist.length==0">
                <img src="./../../../assets/images/common/nodata.png" alt="" />
                <p>还没有订单，赶快去购课吧～</p>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: 'myOrder',
    data() {
        return {
            currentTab: -1,
            orderlist: null,
            timer: null,
            pageInfo: {
                total: 0,
                size: 10,
                pageNum: 1,
                pageSize: 10,
            },
        }
    },
    methods: {
        handleClickTab(val) {
            this.currentTab = val
            this.myOrderList()
        },
        async myOrderList() {
            const params = {
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize,
            }
            const data = {
                status: this.currentTab === -1 ? null : this.currentTab,
            }
            let res = await this.$api.order.myOrderList({params,data})
            if (res.success) {
                this.orderlist = res.data.list || []
                this.pageInfo.total = res.data.total
                this.countDown()
            }
        },
        cancelPayment(order) {
            this.$api.order.cancelOrder(order.orderNo).then(res => {
                console.log(res)
                if (res.code == 0) {
                    this.$message({
                        message: '订单取消成功',
                        type:'success'
                    })
                    this.pageInfo.pageNum = 1
                    this.myOrderList()
                }
            })
        },
        countDown() {
            var that = this
            that.timer = setInterval(function () {
                var orders = that.orderlist
                for (var i = 0; i < orders.length; i++) {
                    var status = orders[i].status
                    if (status == 0 && orders[i].expireTime) {
                        var expireTime = orders[i].expireTime
                        //计算剩余时间差值
                        var leftTime = new Date(expireTime).getTime() - new Date().getTime()
                        if (leftTime > 0) {
                            //计算剩余的分钟
                            var minutes = that.formatNumber(parseInt((leftTime / 1000 / 60) % 60, 10))
                            //计算剩余的秒数
                            var seconds = that.formatNumber(parseInt((leftTime / 1000) % 60, 10))
                            var left_time = minutes + ':' + seconds
                            orders[i].left_time = left_time
                        } else {
                            //改变超时未支付的订单状态
                            that.orderlist[i].status = 8
                        }
                    }
                }
                that.orderlist = orders
                console.log(this.orderlist);
                that.$forceUpdate()
            }, 1000)
        },
        formatNumber(num) {
            return num >= 10 ? num : '0' + num
        },
        onBack() {
            this.myOrderList()
        },
        // 商品详情
        goDetails(item) {
            this.$router.push({
                path: '/course/detail',
                query: {
                    ucode:item.taskUcode
                },
            })
        },
        // 去付款
        goPayment(order) {
            this.$router.push({
                path: '/orderPage',
                query: {
                    orderNo:order.orderNo,
                },
            })
        },
        handleSizeChange(val) {
            this.pageInfo.pageNum = 1
            this.pageInfo.pageSize = val
            this.myOrderList()
        },
        handleCurrentChange(pageNum) {
            this.pageInfo.pageNum = pageNum
            this.myOrderList()
        },
    },
    created() {
        this.myOrderList()
    },
    destroyed() {
        clearInterval(this.timer)
    },
}
</script>
<style lang="scss" scoped>
::v-deep.el-pagination{
    display: flex;
    align-items: center;
     .el-pager{
        li {
            margin: 0 6px;
        }
    }
    .btn-prev{
        margin-right: 6px;
    }
    .btn-next{
        margin-left: 6px;
    }
}
.orderli{
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(34, 98, 188, 0.05);
    margin-bottom:  20px;
    position:  relative;
    border-radius: 6px;
    overflow: hidden;
    .orderCurseLeft{
        position:relative;
    }
    .bottom-order-status{
        position: absolute;
        right:0px;
        top:-15px;
        img{
            width:80px;
        }
    }
    .course-source{
        position: absolute;
        left:16px;
        top:6px;
        img{
            height:20px;
        }
    }
    .ordertop{
        padding-left : 16px;
        height:  48px;
        font-size:  16px;
        line-height:  48px;
        color: #888;
        border-bottom: 1px solid #f4f7fa;
        .ordertime{
            padding-left : 14px;
        }
    }
    .orderCurseInfo{
        display:  flex;
        padding:  16px;
        position: relative;
        .orderCourseImg{
            width : 160px;
            height:  90px;
            border-radius: 6px;
            border : 1px solid #EEEEEE;
            cursor: pointer;
            object-fit: cover;
        }
        .orderCurseRight{
            width:calc(100% - 176px);
            margin-left:  16px;
            display:  flex;
            flex-direction : column;
            justify-content:  space-between;
            h3{
                font-size: 18px;
                font-weight: 550;
                color: #333333;
                line-height: 28px;
                cursor:pointer;
            }
            p{
                .price_key{
                    font-size: 16px;
                    line-height: 20px;
                    color: #333333;
                }
                .price_val{
                    font-size: 18px;
                    line-height: 20px;
                    color: #FF7800;
                    font-weight : 550;
                }
            }
        }
    }
}
.nodata-tip{
    flex-grow : 1;
    justify-content : center;
    display:  flex;
    flex-direction:  column;
    padding-top: 106px;
    padding-bottom: 100px;
    background: #fff;
    img{
        display:  block;
        width:  242px;
        margin : 0 auto;
    }
    p{
        margin-top:  8px;
        color:  #999999;
        line-height:  20px;
        text-align:  center;
    }
}
.bottom-order{
    display:  flex;
    justify-content:  space-between;
    align-items:  flex-end;
    .itemBtn{
        width:  88px;
        height : 32px;
        margin-top:5px;
        color : #fff;
        outline : none;
        border : none;
        background : rgba(49, 111, 255, 1);
        border-radius:  4px;
    }
    .cancel-pay-btn{
        margin-left: 12px;
        color : rgba(49, 111, 255, 1);
        background: #fff;
        border:1px solid rgba(49, 111, 255, 1);
    }
}
.bottom-order-right{
    display : flex;
    align-items : center;
}
.tab{
    padding-bottom:  24px;
    span{
        font-size: 16px;
        color: #999999;
        line-height: 22px;
        margin-right:  32px;
        cursor: pointer;
        &.active{
            position:  relative;
            color:#1a72ff;
            font-weight: 600;
        }
    }
}

.pagenation-wraper{
    margin : 48px auto;
    display: flex;
    justify-content: center;
}
.daoji .time{
    color:#E30000;
}
.btn_pay{
    text-align:right;
}
</style>

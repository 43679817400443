<template>
  <div class="myProfile">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="105px"
      class="demo-ruleForm"
    >
      <el-form-item label="头像:" prop="headImage">
        <label slot="label" class="headLabel"
          >头&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;像：</label
        >
        <div class="headIcon">
          <div v-loading="uploadAvatarLoading">
            <img
              class="headImage"
              v-if="ruleForm.headImage"
              :src="ruleForm.headImage"
              alt=""
            />
            <img
              class="headImage"
              v-else
              src="../../../assets/images/common/userL.png"
              alt=""
            />
          </div>
          <div class="btn">
            <el-upload
              class="avatar-uploader"
              ref="avatarUploader"
              accept="image/*"
              :action="action"
              :headers="headers"
              :show-file-list="false"
              :before-upload="handleAvatarBefore"
              :on-success="handleAvatarSuccess"
              :on-error="handleAvatarError"
            />
            <p class="text">
              {{ ruleForm.headImage ? "更换头像" : "上传头像" }}
            </p>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="姓名：" prop="name">
        <label slot="label">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</label>
        <el-input
          class="input1"
          :maxlength="15"
          v-model="ruleForm.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="昵称：" prop="nickname">
        <label slot="label">昵&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;称：</label>
        <el-input
          class="input1"
          :maxlength="15"
          v-model="ruleForm.nickname"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号：" prop="phone">
        <label slot="label" class="phoneLabel"
          ><span>手&nbsp;机&nbsp;号</span>：</label
        >
        <el-input class="input2" disabled v-model="ruleForm.phone"></el-input>
        <span class="label1 phoneText" @click="jump('账号安全')"
          >更换手机号</span
        >
      </el-form-item>
      <el-form-item label="真实姓名：" prop="">
        <el-input
          class="input1"
          disabled
          v-model="ruleForm.realName"
        ></el-input>
      </el-form-item>
      <el-form-item label="身份证号：" prop="idCardNo">
        <el-input
          class="input1"
          v-model="ruleForm.idCardNo"
          disabled
          v-if="ruleForm.idAuthStatus"
        ></el-input>
        <span class="label1" @click="jump('身份认证')" v-else
          >前往身份认证</span
        >
      </el-form-item>
      <el-form-item label="性别：" prop="gender">
        <label slot="label">性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别：</label>
        <el-radio-group disabled class="radioGroup" v-model="ruleForm.gender">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="出生日期：" prop="birthday">
        <el-date-picker
          disabled
          v-model="ruleForm.birthday"
          :picker-options="pickerOptions"
          style="width: 318px"
          type="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择出生日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="button">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import config from "@/config/url";
export default {
  computed: {},
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      uploadAvatarLoading: false,
      action:
        config.API +
        "/standard-raas/v1/resource/uploadPublicResource?fileType=4",
      headers: {
        token: localStorage.getItem("token"),
      },
      ruleForm: {
        headImage: "",
        realName: "",
        idCardNo: "",
        name: "",
        gender: "",
        birthday: "",
        phone: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getUserInfoSecurity();
  },
  methods: {
    handleAvatarBefore(file) {
      const isImg = file.type.includes("image");
      if (!isImg) {
        this.$message.warning("只能上传图片!");
        return false;
      }
      this.uploadAvatarLoading = true;
    },
    // 头像上传成功
    handleAvatarSuccess(res, file) {
      console.log(res, "=====");
      console.log(file, "===");
      this.ruleForm.headImage = res.data;
      this.uploadAvatarLoading = false;
    },
    handleAvatarError(err) {
      console.log("头像上传失败==", err);
      this.uploadAvatarLoading = false;
      this.$message.error("头像上传失败");
    },
    jump(label) {
      this.$emit("changeTab", label);
    },
    // 获取个人资料
    getUserInfoSecurity() {
      this.$api.usercenter.getUserInfoSecurity({}).then((res) => {
        if (res.data) {
          this.ruleForm = res.data;
          this.$store.commit("subUserInfo", Object.assign({}, res.data));
          localStorage.setItem(
            "subUserInfo",
            JSON.stringify(Object.assign({}, res.data))
          );
        }
      });
    },
    // 更新保存个人资料
    updateUser() {
      const data = {
        ...this.ruleForm,
      };
      this.$api.usercenter.updateUser({ data }).then((res) => {
        if (res.success) {
          this.$message.success("保存成功");
          this.getUserInfoSecurity();
        }
      });
    },
    // 保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateUser();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.headIcon {
  display: flex;
  align-items: center;
  .headImage {
    width: 72px;
    height: 72px;
    border-radius: 50%;
  }
  .btn {
    margin-left: 24px;
    position: relative;
    .avatar-uploader {
      width: 56px;
      position: absolute;
      left: 0;
      ::v-deep {
        .el-upload {
          width: 100%;
          height: 20px;
        }
      }
    }
    p {
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: $WDPrimaryColor;
      line-height: 20px;
    }
  }
}
.input1 {
  width: 318px;
}
.input2 {
  width: 232px;
}
.label1 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: $WDPrimaryColor;
  line-height: 20px;
  cursor: pointer;
}
.phoneText {
  margin-left: 16px;
}
.myProfile {
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px 0 50px 250px;
}
.headLabel {
  position: relative;
  top: 20px;
}
.phoneLabel {
  span {
    letter-spacing: 1px;
  }
}
::v-deep {
  .button {
    .el-form-item__content {
      margin-left: 15px !important;
    }
  }
  .el-form-item {
    margin-bottom: 28px;
    .el-button {
      width: 370px;
      height: 44px;
      background: $WDPrimaryColor;
      border-radius: 22px;
    }
  }
  .el-form-item .el-form-item__label,
  .el-form-item .phoneLabel span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333;
    line-height: 22px;
    padding-top: 10px;
  }
}
</style>

<template>
    <div class="examList" v-loading="loading1">
      <div class="item" v-for="item in examList" :key="item.id">
        <img :src="item.coverUrl" class="cover" alt="">
        <div class="info">
          <p class="name">{{ item.examName }}</p>
          <p class="time examTime">考试时间：{{ item.isLimitTime ? `${item.startTime.replace(/-/g,'.')} - ${item.endTime.replace(/-/g,'.')}`:'长期有效' }}</p>
          <div class="examBotton">
            <p class="source">
              <span>来&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;源：</span>
              <span class="sourceName">{{ item.sourceName }}</span>
            </p>
            <p class="btn" @click="goExam(item)">
              {{item.buttonStatus === 1? '开始考试' : item.buttonStatus === 2 ? '重考' :'查看'}}
            </p>
          </div>
        </div>
        
      </div>
      <article v-if="noData" class="ListEmptyData">
          <img src="../../../assets/images/emptyData.png" alt />
          <p>暂无数据</p>
      </article>
      <p class="loadingmore" v-if="loading">加载中...</p>
      <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
    </div>
</template>

<script>
import $ from 'jquery';
export default {
  data(){
    return{
      loading1:false,
      examList:[],
      paging: {
          params: {
              pageNum: 1,
              pageSize: 10
          },
          total: 0
      },
      loading:false,
      noData:null
    }
  },
  computed: {
    noMore() {
      return this.examList.length === this.paging.total && this.paging.total !== 0
    },
  },
  created(){
    this.getData()
  },
  mounted() {
    $(window).scroll(() => {
      //判断是否滑动到页面底部
      if (
        Math.round($(window).scrollTop()) ===
        $(document).height() - $(window).height()
      ) {
        // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
        // console.log('页面底部')

        setTimeout(this.reachBottom(), 1000)
      }
    });
  },
  methods:{
    goExam(item){
        if(item.showLock){
            if(item.lockCourseRate){
                this.$message.warning({
                    message:'该考试未开启，开启条件为前面的课程学习进度达到'+item.lockCourseRate+'%',
                    offset:70
                })
            }
            if(item.lockExamCondition){
                this.$message.warning({
                    message:'该考试未开启，开启条件为前面的考试得分及格',
                    offset:120
                })
            }
        }else {
            this.$router.push({
                path:'/examDetails',
                query:{
                    bussinessId:item.bussinessId,
                    examId:item.examId,
                    type:item.sourceType,
                    title:item.sourceName,
                    pathName:this.$route.path,
                    backMoudle:'exam',
                    backPath:this.$route.path,
                    timeSlot:item.isLimitTime,
                    startTime:item.startTime,
                    endTime:item.endTime
                }
            })
        }
        

    },
    reachBottom() {
        if (this.paging.total > this.paging.params.pageNum * this.paging.params.pageSize) {
            this.paging.params.pageNum = this.paging.params.pageNum + 1;
            this.loading = true;
            this.getData("reachBottom");
        } else {
            this.loading = false;
        }
    },
    getData(){
      const params = {
          pageNum: this.paging.params.pageNum,
          pageSize: this.paging.params.pageSize,
      }
      this.$api.exam.findUserExams({params}).then(({ data: { list, total } }) => {
          this.loading1 = false;
          if(list && list.length){
              this.noData = false
          }else {
              this.noData = true
          }
          if(this.paging.params.pageNum>1){
              this.examList = this.examList.concat(list);
          } else {
              this.examList = list;
          }
          this.paging.total = total;
      });
    }
  }
}
</script>

<style lang="scss" src="../asset/css/list.scss" scoped>

</style>
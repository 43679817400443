<template>
  <input
    style="visibility: hidden"
    id="file"
    type="file"
    class="hp-uploadBtn1"
    ref="file"
    :multiple="multiple"
    :accept="accept"
    @change="handleClickUpload($event)"
  />
</template>
<script>
export default {
  name: "UploadOss",
  props: {
    maxSize: {
      type: Number,
      required: true,
    },
    fileCount:{
      type:Number,
      default:0
    },
    fileNum:{
      type:Number,
      default:100
    },
    accept: {
      type: String,
      default: "",
    },
    reg: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      partSize: 1024 * 1024, // 每个分片大小(byte)
      parallel: 3, // 同时上传的分片数
      credentials: "", // STS凭证
      ossClient: null, // oss客户端实例
      counts: 0,
      datas: [],
      list: [],
      selectFileList: [],
      bucketName: "",
    };
  },
  methods: {
    handleClick() {
      console.log('==============')
      this.$refs.file.click();
    },
    // 创建OSS Client
    async initOSSClient(datas) {
      const OSS = require("ali-oss");
      this.bucketName = datas.data.privateBucket;
      this.ossClient = new OSS({
        region: datas.data.region,
        accessKeyId: datas.data.accessKeyId,
        accessKeySecret: datas.data.accessKeySecret,
        bucket: this.bucketName /* 装图片的桶名 */,
        stsToken: datas.data.securityToken,
        endpoint: datas.data.endpoint, // 新增加
      });
    },
    // 上传客户见证
    async handleClickUpload(e, files) {
      this.$parent.uploadLoading = true;
      const api ='getAliyunDocConf';
      this.datas = await this.$api.operationHomeWork[api]();
      await this.initOSSClient(this.datas);
      let filesList = files || e.target.files;

      for (let i = 0; i < filesList.length; i += 1) {
        if (filesList[i].size > this.maxSize) {
          this.$message.warning("文件大小超出限制");
          this.$refs.file.value = null;
          this.$parent.uploadLoading = false;
          return;
        }
      }
      this.counts = filesList.length;
      const counts = filesList.length + this.fileCount;
      if (counts > this.fileNum) {
        this.$message.warning(`最多可上传${this.fileNum}个文件`);
        this.$refs.file.value = null;
        this.$parent.uploadLoading = false;
      } else {
        this.selectFileList = [];
        for (let i = 0; i < this.counts; i++) {
          const fileInfo = {
            file: filesList[i],
            name: filesList[i].name,
            index: i,
            size: filesList[i].size,
          };
          localStorage.removeItem("ossCheckpoints-" + i);

          this.selectFileList.push(fileInfo);
        }
        this.uploadFileList(0);
      }
    },
    uploadFileList(index) {
      //检查要上传的文件是否存在
      const file = this.selectFileList[index];
      if (!file) return;
      this.$set(file, "path", this.creatFileUrl(file.name));
      this.handleUpload(file)
        .then((res) => {
          // 判断是否为中止上传，isCancel取消正在上传
          if (!res.isCancel) {
            if (res.index <= this.counts) {
              this.uploadFileList(res.index + 1);
            }
          } else {
            localStorage.removeItem("ossCheckpoints-" + index);
            this.selectFileList.splice(index, 1);
            if (this.selectFileList.length > 0) {
              this.selectFileList.forEach((it, i) => {
                it.index = i;
              });

              this.uploadFileList(index);
            } else {
              const fileRef = this.$refs.file;
              fileRef.value = "";
            }
          }
        })
        .catch((e) => {
          this.$message.error("上传出错了，请刷新页面重试");
          console.debug(e);
        });
    },
    async abortUpload(obj) {
      await this.ossClient.abortMultipartUpload(obj.uploadName, obj.uploadId);
    },
    handleUpload(obj) {
      const fileType = "to-convert-doc";
      const uploadUrl = `${this.datas.data.filePathPrefix}${fileType}/${obj.path}`;
      const localCpt = localStorage.getItem("ossCheckpoints-" + obj.index);
      const checkpoint = JSON.parse(localCpt);
      let beforeNames = "";
      let afterNames = "";
      // 拿到出去后缀的名字
      var counts = obj.name.split(".").length;
      obj.name.split(".").forEach((ite, ind) => {
        if (ind <= counts - 2) {
          if (ind == counts - 2) {
            beforeNames += ite;
          } else {
            beforeNames += ite + ".";
          }
        }
      });
      afterNames = obj.name.split(".")[counts - 1];
      return new Promise((resolve, reject) => {
        this.ossClient
          .multipartUpload(uploadUrl, obj.file, {
            checkpoint: checkpoint,
          })
          .then((result) => {
            this.$parent.uploadLoading = false;
            const temp = {
              size: obj.size,
              url: `https://${this.datas.data.privateBucket}.${this.datas.data.endpoint}/${result.name}` ,
              bucketName: this.privateBucket,
              id: JSON.parse(result.etag),
              urlTrue: result.name,
              type: afterNames.toLocaleLowerCase(),
              name: beforeNames.toLocaleLowerCase(),
            };
            this.$emit("file-obj", temp);
            const resObj = Object.assign({}, temp, { index: obj.index });
            resolve(resObj);
            this.$refs.file.value = null;
          })
          .catch((err) => {
            console.debug("Resume multipart upload failed === ", err);
            if (err.name === "cancel") {
              const resObj = Object.assign({ isCancel: true }, obj, err);
              resolve(resObj);
            } else {
              reject(err);
            }
          });
      });
    },
    creatFileUrl(name) {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const timestamp = new Date().getTime();
      const fileSuffix = name.lastIndexOf(".");
      const fileExt = name.substring(fileSuffix); // 后缀名
      const storeAs = `${timestamp}${fileExt}`;
      return `${year}/${this.add0(month)}/${storeAs}`;
    },
    add0(m) {
      return m < 10 ? `0${m}` : m;
    },
  },
};
</script>
<style lang="scss" scoped></style>
<template>
  <div class="accountSafe">
    <template>
      <ul class="safeul">
        <li class="safeli">
          <div class="top">
            <div class="imgwrapper">
              <svg-icon icon-class="personal_look_icon"></svg-icon>
            </div>
            <div class="txt">
              <div class="title">
                登录密码
              </div>
              <div class="desc">
                建议您定期更换密码，设置安全性高的密码可以使帐号更安全
              </div>
            </div>
          </div>
          <div class="bottom">
            <el-button class="opebtn" type="primary" v-if="!isSettingPassword" @click="settingPasswordDialog = true">
              设置密码
            </el-button>
            <el-button class="opebtn" type="primary" v-else @click="openModifyPswoDialog">
              修改密码
            </el-button>
          </div>
        </li>
        <li class="safeli">
          <div class="top">
            <div class="imgwrapper">
              <svg-icon icon-class="personal_phone_icon"></svg-icon>
            </div>
            <div class="txt">
              <div class="title">
                {{ userphone ? `安全手机 ${userphone}` : '当前还未绑定安全手机' }}
              </div>
              <div class="desc">
                安全手机可以用于登录帐号，重置密码或其他安全验证
              </div>
            </div>
          </div>
          <div class="bottom">
            <el-button class="opebtn" type="primary" @click="toPhone">
              {{ userphone ? '更换' : '去绑定' }}
            </el-button>
          </div>
        </li>

      </ul>
    </template>
    <!-- 设置密码 -->
    <el-dialog class="settingPasswordDialog modifyDialog" :visible.sync="settingPasswordDialog" :close-on-click-modal="false"
      width="600px" center>
      <div slot="title" class="title">
        设置登录密码
      </div>
      <div class="authCode" :class="{'hasSetted':isSettingPassword}">
        <el-input v-model="settingPassword.authCode" placeholder="请输入验证码"></el-input>
        <el-button type="text" @click="getAuthCodeByCurr(1)">{{ 0 >= Countdown ? '获取验证码' : Countdown + 's'}}</el-button>
      </div>
      <el-button type="text" @click="toold">原登录密码验证</el-button>
      <el-input v-model="settingPassword.passwordNew" @blur="comparePassword('setting')" :type="psdtype2"
        auto-complete="new-password" placeholder="设置8至20位新登录密码">
        <template slot="append">
          <span @click="handleshowPsd(2)" class="pwd-icon">
            <svg-icon icon-class="pwd-yincang" v-if="psdtype2 == 'text'"></svg-icon>
            <svg-icon v-else icon-class="pwd-zhanshi"></svg-icon>
          </span>
        </template>
      </el-input>
      <el-input v-model="settingPassword.repsw" @blur="comparePassword('setting')" :type="psdtype3" auto-complete="new-password"
        placeholder="请再次输入登录密码">
        <template slot="append">
          <span @click="handleshowPsd(3)" class="pwd-icon">
            <svg-icon icon-class="pwd-yincang" v-if="psdtype3 == 'text'"></svg-icon>
            <svg-icon v-else icon-class="pwd-zhanshi"></svg-icon>
          </span>
        </template>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="settingPasswordDialog = false">
          取消
        </el-button>
        <el-button type="primary"
          :disabled="!settingPassword.authCode || !settingPassword.passwordNew || !settingPassword.repsw"
          :class="{ grayBtn: !settingPassword.authCode || !settingPassword.passwordNew || !settingPassword.repsw }"
          @click="resetPassword4AuthCode">
          确定
        </el-button>
      </span>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog class="modifyPswoDialog modifyDialog" :visible.sync="modifyPswoDialog" :close-on-click-modal="false"
      width="600px" center>
      <div slot="title" class="title">
        修改登录密码
      </div>
      <el-input v-model="modifyPassword.oldPassword" :type="psdtype1" auto-complete="new-password" placeholder="请输入原登录密码" style="margin-bottom: 0">
        <template slot="append">
          <span @click="handleshowPsd(1)" class="pwd-icon">
            <svg-icon icon-class="pwd-yincang" v-if="psdtype1 == 'text'"></svg-icon>
            <svg-icon v-else icon-class="pwd-zhanshi"></svg-icon>
          </span>
        </template>
      </el-input>
      <el-button type="text" @click="forgetPassword">忘记密码？</el-button>
      <el-input v-model="modifyPassword.password" @blur="comparePassword('modify')" :type="psdtype2" auto-complete="new-password"
        placeholder="设置8至20位新登录密码">
        <template slot="append">
          <span @click="handleshowPsd(2)" class="pwd-icon">
            <svg-icon icon-class="pwd-yincang" v-if="psdtype2 == 'text'"></svg-icon>
            <svg-icon v-else icon-class="pwd-zhanshi"></svg-icon>
          </span>
        </template>
      </el-input>
      <el-input v-model="modifyPassword.repsw" @blur="comparePassword('modify')" :type="psdtype3" auto-complete="new-password"
        placeholder="请再次输入登录密码">
        <template slot="append">
          <span @click="handleshowPsd(3)" class="pwd-icon">
            <svg-icon icon-class="pwd-yincang" v-if="psdtype3 == 'text'"></svg-icon>
            <svg-icon v-else icon-class="pwd-zhanshi"></svg-icon>
          </span>
        </template>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="modifyPswoDialog = false">
          取消
        </el-button>
        <el-button type="primary"
          :disabled="!modifyPassword.oldPassword || !modifyPassword.password || !modifyPassword.repsw"
          :class="{ grayBtn: !modifyPassword.oldPassword || !modifyPassword.password || !modifyPassword.repsw }"
          @click="postAccountChangePassword">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 更换手机 -->
    <el-dialog class="modifyPhoneDialog modifyDialog" :visible.sync="modifyPhoneDialog" :close-on-click-modal="false"
      width="600px" center @closed="handleClose">
      <div slot="title" class="title">
        更换安全手机
      </div>
      <div class="progress">
        <div class="step1" :class="{ highlight: modifyPhoneStep >= 1 }">
          <img src="../../../assets/images/personal/step1-icon.png" alt="">
          <div class="stepTxt">1、验证身份</div>
        </div>
        <p class="line" :class="{ highlightLine: modifyPhoneStep >= 2 }"></p>
        <div class="step2" :class="{ highlight: modifyPhoneStep >= 2 }">
          <img v-if="modifyPhoneStep >= 2" src="../../../assets/images/personal/step2-icon.png" alt="">
          <img v-else  src="../../../assets/images/personal/step2-1.png" alt="">
          <div class="stepTxt">2、修改手机号</div>
        </div>
        <p class="line" :class="{ highlightLine: modifyPhoneStep >= 3 }"></p>
        <div class="step3" :class="{ highlight: modifyPhoneStep >= 3 }">
          <img v-if="modifyPhoneStep >= 3"  src="../../../assets/images/personal/step3-icon.png" alt="">
          <img v-else src="../../../assets/images/personal/step3-1.png" alt="">
          <div class="stepTxt">3、完成更换</div>
        </div>
      </div>
      <template v-if="modifyPhoneStep == 1">
        <div class="desc">
          <p class="desc1">已绑定的手机：<span>{{ userphone }}</span></p>
          <!-- <p class="desc2">若该手机号已无法使用请联系客服</p> -->
          <p class="desc2">若该手机号已无法使用，请点击<span class="face" @click="handleFaceRecognition">人脸识别</span>验证身份</p>
          <p class="desc3">（注：实名认证后才能人脸识别验证身份）</p>
        </div>
        <div id="QRcode" v-if="isShowQRcode" style="width:180px;height:180px;margin:0 auto;margin-bottom:24px"></div>

        <el-input class="noRightRadius" v-else v-model="phoneCode" placeholder="请输入验证码">
          <template slot="append">
            <span @click="getAuthCodeByCurr(2)"
              :style="{ color: 0 >= Countdown2 ? '#316fff' : '#BFC8D9', cursor: 'pointer' }">
              {{ 0 >= Countdown2 ? '获取验证码' : Countdown2 + 's' }}
            </span>
          </template>
        </el-input>
      </template>
      <template v-else-if="modifyPhoneStep == 2">
        <div class="form">
          <el-input v-model="modifyAccount.account" placeholder="请输入新的手机号"></el-input>
          <el-input class="noRightRadius" v-model="modifyAccount.authCode" placeholder="请输入验证码">
            <template slot="append">
              <span @click="getAuthCode()"
                :style="{ color: 0 >= Countdown2 ? '#316fff' : '#BFC8D9', cursor: 'pointer' }">
                {{ 0 >= Countdown2 ? '获取验证码' : Countdown2 + 's' }}
              </span>
            </template>
          </el-input>
        </div>
      </template>
      <template v-else>
        <div class="desc successdesc">
          <p class="desc1 ">手机号码更换成功</p>
          <p class="desc2">请在下次使用新的手机号码进行登录</p>
        </div>
      </template>
      <span slot="footer" v-if="!isShowQRcode || modifyPhoneStep !== 1" class="dialog-footer">
        <el-button type="primary" :class="{ grayBtn: modifyPhoneBtnColor }" @click="handleModifyPhone()">
          {{ modifyPhoneStep == 3 ? '返回' : '下一步'}}
        </el-button>
      </span>
    </el-dialog>
    <!-- 绑定手机号 -->
    <el-dialog class="bindPhoneDialog modifyDialog" :visible.sync="bindPhoneDialog" :close-on-click-modal="false"
      width="600px" center>
      <div slot="title" class="title">
        绑定安全手机
      </div>
      <el-input v-model="bindAccount.account" placeholder="请输入手机号"></el-input>
      <el-input class="noRightRadius" v-model="bindAccount.authCode" placeholder="请输入验证码">
        <template slot="append">
          <span :style="{ color: 0 >= Countdown4 ? '#316FFF' : '#666', cursor: 'pointer' }"
            @click="postAccountGetAuthCode4">{{
              0 >= Countdown4 ? '获取验证码' : Countdown4 + 's'
            }}</span>
        </template>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :class="{ grayBtn: !bindAccount.authCode }" @click="handleBind">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 人脸识别 -->
    <facedialog v-if="verification" :pauseFrame="true" @closeFacedialog="handleCloseFacedialog" :confirmdialog="confirmdialog" :type="4"
      :address="{}" :failAddress="{}"></facedialog>
  </div>
</template>

<script>
import MD5 from 'js-md5'
import QRCode from 'qrcodejs2'
import { isPhone, passReg } from '@/utils/validator'
export default {
  name: 'accountSafe',
  data() {
    return {
      CountdownInterval: null,
      Countdown: 0,
      settingPasswordDialog: false,
      modifyPswoDialog: false,
      modifyPhoneDialog: false,
      bindPhoneDialog: false,
      readDialog: false,
      isread: false,
      confirmdialog: {
        show: false
      },
      settingPassword: {
        authCode: '',
        password: '',
        passwordNew: '',
      },
      modifyPassword: {
        password: '',
        oldPassword: '',
        repsw: '',
      },
      modifyAccount: {
        account: '',
        authCode: '',
      },
      bindAccount: {
        account: '',
        authCode: '',
      },
      email: '',
      showPsd: false,
      psdtype1: 'password',
      psdtype2: 'password',
      psdtype3: 'password',
      userphone: '',
      modifyPhoneStep: 1,
      phoneCode: '', //验证身份
      mailCode: '', //验证身份
      Countdown1: 0,
      Countdown2: 0,
      Countdown3: 0,
      Countdown4: 0,
      isRealName: false, //是否实名认证
      isShowQRcode: false, //显示实名认证二维码
      verification: false, //人脸识别
      result: false,
      isSettingPassword:true,
    }
  },
  watch: {
    // 是否已进行人脸验证
    result(val) {
      if (val == true) {
        this.modifyPhoneStep = 2
      }
    },
  },
  computed: {
    modifyPhoneBtnColor() {
      if (this.modifyPhoneStep == 1 && !this.phoneCode) {
        return true
      } else if (this.modifyPhoneStep == 2 && !this.modifyAccount.authCode) {
        return true
      } else {
        return false
      }
    },
    phoneNumber() {
      return this.$store.state.userInfo.phone
    },
  },
  created(){
    this.alreadySetPasswordAndPasswordNotNull()
  },
  mounted() {
    this.getCurrentUserAccount()
  },
  methods: {
    toold(){
      this.settingPasswordDialog = false
      this.modifyPswoDialog = true
    },
    forgetPassword(){
      this.modifyPswoDialog = false
      this.settingPasswordDialog = true
    },
    openModifyPswoDialog() {
      this.modifyPassword = {
        password: '',
        oldPassword: '',
        repsw: '',
      }
      this.modifyPswoDialog = true
    },
    // 已经初始化设置过密码
    alreadySetPasswordAndPasswordNotNull(){
      this.$api.usercenter.alreadySetPasswordAndPasswordNotNull({}).then(res => {
        this.isSettingPassword = res.data;
      })
    },
    // 获取验证码（根据当前已登录账号）
    getAuthCodeByCurr(type){
      if(type === 1){
        if (this.Countdown > 0) return;
        this.Countdown = 60;
        this.setCountdown();
      }else if(type === 2){
        if (this.Countdown2 > 0) return
        this.Countdown2 = 60
        this.setCountdown2()
      }
      this.$api.usercenter.getAuthCodeByCurr({})
    },
    // 输入手机号 获取验证码
    async getAuthCode() {
      const data = { account: this.modifyAccount.account, type: 1 };
      if (this.Countdown2 > 0) return;
      this.Countdown2 = 60;
      this.setCountdown2();
      this.$api.usercenter.postAccountGetAuthCode({ data })
    },
    setCountdown() {
      this.CountdownInterval = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          clearInterval(this.CountdownInterval);
        }
      }, 1000);
    },
    handleCloseFacedialog() {
      this.verification = false;
      let video = document.getElementById('faceVideo')
      if(video){
        video.srcObject.getTracks()[0].stop()
      }
    },
    async handleFaceRecognition() {
      await this.postIsRealNameAuthentication()
      if (this.isRealName) {
        // 人脸认证
        this.verification = true
      } else {
        // 实名认证
        if (this.QRCode) return
        this.isShowQRcode = true
        this.$nextTick(() => {
          this.qrcode()
        })
        // 开启轮询，判断用户是否实名认证
        this.startPoll()
      }
    },
    startPoll() {
      this.pollId = setInterval(() => {
        this.postIsRealNameAuthentication('poll')
      }, 3000)
    },
    async postIsRealNameAuthentication(type) {
      return await this.$api.usercenter.postIsRealNameAuthentication({}).then((res) => {
        const m = `${localStorage.getItem('token')}true`
        this.isRealName = res.data === MD5(m)
        localStorage.setItem('isRealName', this.isRealName)
        // 开启轮询后，用户通过实名认证后可修改手机号
        if (type && this.isRealName) {
          this.modifyPhoneStep = 2
          clearInterval(this.pollId)
        }
      })
    },
    qrcode() {
      let domainArr = localStorage.getItem('domain')
      let domainUrl = domainArr && JSON.parse(domainArr).find((item) => item.frontend_template_id == 4).domain
      this.QRCode = new QRCode('QRcode', {
        width: 180,
        height: 180, // 高度
        text: `${window.location.protocol}//${domainUrl}/login?phone=${this.phoneNumber}&redirect=/idcard`, // 二维码内容
        display: 'none',
      })
    },
    // 关闭换绑手机号弹框
    handleClose() {
      this.QRCode = false
      this.isShowQRcode = false
      clearInterval(this.pollId)
    },
    // 获取已绑定手机
    getCurrentUserAccount() {
      this.$api.usercenter.getCurrentUserAccount().then((res) => {
        if (res.data) {
          this.userphone = res.data
        }
      })
    },
    // 设置密码
    async resetPassword4AuthCode(){
      const params = {
        authcode:this.settingPassword.authCode
      }
      const res = await this.$api.usercenter.checkAuthCodeByCurr({params});
      if(!res.data){
        return false;
      }
      if (!this.settingPassword.authCode || !this.settingPassword.passwordNew || !this.settingPassword.repsw) return
      if (!this.comparePassword('setting')) return
      if (!passReg.test(this.settingPassword.passwordNew)) {
        this.$message.warning('请设置8-20位，数字、大小写字母、字符组合密码')
        return
      }
      let data = { 
        authCode:this.settingPassword.authCode,
        password:this.settingPassword.passwordNew
       }
      this.$api.usercenter.resetPassword4AuthCode({data}).then((res) => {
        if (res.data) {
          this.settingPasswordDialog = false
          this.isSettingPassword = true
          this.$message.success('密码设置成功')
          //重置表单
          this.settingPassword = {
            authCode: '',
            passwordNew: '',
            repsw: '',
          }
        }
      })
    },
    // 修改密码
    postAccountChangePassword() {
      if (!this.modifyPassword.oldPassword) {
        this.$message.warning('请输入原登录密码')
        return
      } 
      if (!this.modifyPassword.password) {
        this.$message.warning('请输入登录密码')
        return
      } 
      if (!this.modifyPassword.repsw) {
        this.$message.warning('请再次输入登录密码')
        return
      }
      if (!this.comparePassword('modify')) return
      if (!passReg.test(this.modifyPassword.password)) {
        this.$message.warning('请设置8-20位，数字、大小写字母、字符组合密码')
        return
      }
      const {oldPassword,password} = this.modifyPassword;
      let data = { 
        oldPassword:MD5(oldPassword).toLowerCase(),
        password
       }
      this.$api.usercenter.resetPassword4OldPassword({data}).then((res) => {
        if (res.data) {
          this.modifyPswoDialog = false;
          this.$message.success({
            message:'密码修改成功，请重新登录',
            onClose:()=>{
              localStorage.clear();
              this.$router.push('/login')
            }
          })
        }
      })
    },
    handleshowPsd(num) {
      let type = this['psdtype' + num]
      if (type === 'password') {
        this['psdtype' + num] = 'text'
      } else {
        this['psdtype' + num] = 'password'
      }
    },
    comparePassword(type) {
      
      let result = true
      if(type === 'setting'){
        if (this.settingPassword.passwordNew && this.settingPassword.repsw) {
          if (this.settingPassword.passwordNew !== this.settingPassword.repsw) {
            this.$message.warning('两次密码输入不一致')
            result = false
          }
        }
      }else{
        if (this.modifyPassword.password && this.modifyPassword.repsw) {
          if (this.modifyPassword.password !== this.modifyPassword.repsw) {
            this.$message.warning('两次密码输入不一致')
            result = false
          }
        }
      }
      
      return result
    },
    // 绑定手机号、邮箱
    handleBind() {
      let params = { ...this.bindAccount }
      this.$api.usercenter.bindUserAccount(params).then((res) => {
        if (res.data) {
          this.getUserInfo()
          this.getCurrentUserAccount()
          //重置表单
          this.bindAccount = {
            account: '',
            authCode: '',
          }
          this.Countdown1 = 0
          this.Countdown4 = 0
        }
      })
    },
    toPhone() {
      if (!isPhone.test(this.bindAccount.account)) {
        this.bindAccount.account = ''
        this.bindAccount.authCode = ''
      }
      if (!isPhone.test(this.modifyAccount.account)) {
        this.modifyAccount.account = ''
        this.modifyAccount.authCode = ''
      }
      if (this.userphone) {
        this.modifyPhoneDialog = true
      } else {
        this.bindPhoneDialog = true
      }
    },
    // 更换手机
    async handleModifyPhone() {
      if (this.modifyPhoneStep == 1) {
        await this.checkAuthCodeByCurr()
        this.Countdown2 = 0
        this.modifyAccount.account = ''
        this.modifyAccount.authCode = ''
      } else if (this.modifyPhoneStep == 2) {
        await this.changePhone();
      } else {
        this.modifyPhoneDialog = false
        this.modifyPhoneStep = 1
        //重置表单
        this.modifyAccount.account = ''
        this.modifyAccount.authCode = ''
        this.Countdown2 = 0
      }
    },
    // 验证身份 根据当前登录账号
    checkAuthCodeByCurr(){
      const params = {
        authcode:this.phoneCode
      }
      this.$api.usercenter.checkAuthCodeByCurr({params}).then(res => {
        if(res.data){
          this.modifyPhoneStep = 2;
        }

      })
    },
    changePhone(){
      const data = {
        phone:this.modifyAccount.account,
        newAuthCode:this.modifyAccount.authCode,
        oldAuthCode:this.phoneCode // 第一步验证码
      }
      this.$api.usercenter.changePhone({data}).then(res => {
        if(res.data){
          this.modifyPhoneStep = 3;
          this.getCurrentUserAccount()
          this.getUserInfoSecurity();
          this.getUserInfo()
        }
      })
    },
    getUserInfoSecurity() {
      this.$api.usercenter.getUserInfoSecurity({}).then((res) => {
        this.$store.commit('subUserInfo', Object.assign({},res.data))
          localStorage.setItem('subUserInfo', JSON.stringify(Object.assign({},res.data)))
      })
    },
    getUserInfo() {
      this.$api.usercenter.getUserInfo({}).then((res) => {
        this.$store.commit('userInfo', res.data)
        localStorage.setItem('userInfo', JSON.stringify(res.data))
      })
    },
    // 绑定手机 发送验证码
    async postAccountGetAuthCode4() {
      const data = { account: this.bindAccount.account, type: 2 }
      this.bindAccount.authCode = null
      if (isPhone.test(this.bindAccount.account)) {
        if (this.Countdown4 > 0) return
        this.Countdown4 = 60
        this.setCountdown4()
        this.$sensors.track('UM_Click_Verification_Code_Button')
        this.$api.usercenter.getAuthCodePublic({ data })
      } else {
        this.$message.error('请输入正确的手机号')
      }
    },
  
    setCountdown2() {
      this.CountdownInterval2 = setInterval(() => {
        this.Countdown2--
        if (this.Countdown2 <= 0) {
          clearInterval(this.CountdownInterval2)
        }
      }, 1000)
    },
    
    setCountdown4() {
      this.CountdownInterval4 = setInterval(() => {
        this.Countdown4--
        if (this.Countdown4 <= 0) {
          clearInterval(this.CountdownInterval4)
        }
      }, 1000)
    },
    backHome() {
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss" src="../asset/css/accountSafe.scss" scoped>
</style>

<template>
  <el-dialog title="作业详情" :visible.sync="dialogVisible.show" width="815px" center :before-close="handleClose">
    <div class="content">
      
      <img :src="`https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/workStatus${userInfo.status === 2 ? '1' : userInfo.status === 3 ? '3' : '2'}.png`" class="workStatusIcon" alt="">
      <!-- 作业信息 -->
      <div class="workInfo">
        <p class="workName title">{{ workInfo.name }}</p>
        <div class="userInfo">
          <p class="userCon">
            <span class="label">来自：</span>
            <span class="val">{{ userInfo.userName }}</span>
            <span class="line"></span>
            <span class="label">提交时间：</span>
            <span class="val">{{ userInfo.submitTime }}</span>
          </p>
          <p class="remark">
            <span class="label">作业描述：</span>
            <span class="val">{{ workInfo.remark }}</span>
          </p>
        </div>
        <FileList :list="attachmentList" :is-size="false" :is-download="true" />
      </div>
      <!-- 用户提交作业内容和审批结果 -->
      <div class="userWorkCon">
        <div class="tabs">
          <p class="tabItem" @click="tabIndex = 1" :class="{ tabActive: tabIndex === 1 }">作业内容</p>
          <p class="tabItem" @click="tabIndex = 2" :class="{ tabActive: tabIndex === 2 }">审批结果</p>
        </div>
        <div class="workSubmitCon" v-if="tabIndex === 1">
          <div class="workItem" v-for="(item, index) in workList" :key="index">
            <p class="step">第{{ index + 1 }}步</p>
            <p class="remark">{{ item.remark }}</p>
            <template v-if="item.fileList && item.fileList.length">
              <p class="label">附件（{{ item.fileList.length }}）</p>
              <file-list :list="item.fileList" :is-size="false" :is-download="true" :is-preview="true" />
            </template>
          </div>
        </div>
        <template v-if="tabIndex === 2">
          <approvalProcess :approve-process="approvalProcessList" />
        </template>
      </div>
      <template v-if="type === 1">
        <p class="line"></p>
        <!-- 审批 -->
        <div class="approval">
          <p class="title">审批</p>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="学员打分：" prop="score" v-if="workInfo.maxScore">
              <p class="score">
                <span>满分</span>
                <el-input v-model="ruleForm.score"  @input="checkScore" class="scoreInput"></el-input>
              </p>
              <p class="tip">请输入0-{{ workInfo.maxScore }}分整数，满分{{ workInfo.maxScore }}分</p>
            </el-form-item>
            <el-form-item label="评语：" prop="content">
              <el-input v-model="ruleForm.content" maxlength="3000" placeholder="请输入评语文字（最多输入3000字）" :rows="10"
                show-word-limit type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="上传附件：" prop="">
              <div class="uploadFileBox">
                <div class="uploadContainer">
                  <div class="uploadBtn" @click="handleUploadBtn()">
                    <i class="el-icon-plus"></i>
                    <span>本地上传</span>
                  </div>
                  <p class="tip uploadTip">上传的附件将会对学员展示作业作为参考。支持图片、音视频、文档、压缩包、HTML、SCORM，最多支持5个附件</p>
                  <FileList :list="approvalFileList" :is-del="true"></FileList>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <uploadMutiVue ref="uploadFileAll" :fileCount="fileCount" :fileNum="5" :maxSize="1024 * 1024 * 500"
      @file-obj="getFileObjPic" />

    </div>
    <template v-if="type === 1">
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitForm('ruleForm', 2)" type="info">驳回</el-button>
        <el-button type="primary" @click="submitForm('ruleForm', 1)">通过</el-button>
      </span>
    </template>

    
  </el-dialog>
</template>

<script>
import FileList from '../../operationHomeWork/components/fileList';
import approvalProcess from '../../operationHomeWork/components/approval-process';
import uploadMutiVue from '../../operationHomeWork/components/upload-muti.vue';
export default {
  props: {
    dialogVisible: {
      type: Object,
      default: () => { return { show: false } }
    },
    currentWork: {
      type: Object,
      default: () => { return {} }
    },
    type:{
      type:Number,
      default:1
    }
  },
  components: { FileList, approvalProcess, uploadMutiVue },
  data() {
    return {
      fileCount: 0,// 已上传文件数
      attachmentList: [],//作业附件
      workInfo: {},
      workRemark: '',
      userInfo: {},
      tabIndex: 1,
      approvalProcessList: [],
      workList: [],
      approvalFileList: [],
      ruleForm: {
        score: 0,
        content: '',
        annex: '',
        id: this.currentWork.id,
        approvalStatus: ''
      },
      rules: {
        content: [
          { required: true, message: '请输入评语', trigger: 'blur' },
        ],
      }
    }
  },
  created() {
    this.getHomework();
    this.getHomeWorkDetailInfo();
    this.approvalRecord()
  },
  methods: {
    // 只能输入正整数
    checkScore(e) {
      let maxScore = this.ruleForm.maxScore;
      let reg = /^(\d{1,2}|100)$/;
      if (e.match(reg)) {
        this.ruleForm.score = e;
      } else {
        this.ruleForm.score = '';
      }
      if (e.length >= 2 && e[0] == 0) {
        this.ruleForm.score = '';
      }
      if (e > maxScore || e < 0) {
        this.ruleForm.score = maxScore;
      } else if (e == '') {
        this.ruleForm.score = '';
      }

    },
    handleUploadBtn() {
      if (this.approvalFileList.length >= 5) {
        this.$message.warning('最多支持上传5个附件')
        return false;
      }
      this.fileCount = this.approvalFileList.length;
      this.$refs.uploadFileAll.handleClick();
    },
    submitForm(formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.approval(type)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getFileObjPic(file) {
      this.approvalFileList.push({
        url: file.url,
        name: file.name,
        type: file.type,
        size: file.size
      })
      console.log('file', file);

    },
    approval(type) {
      const data = {
        ...this.ruleForm,
        annex: JSON.stringify(this.approvalFileList),
        approvalStatus: type
      }
      this.$api.operationHomeWork.approval({ data }).then(res => {
        if (res.success) {
          if (type == 1) {
            this.$message.success('审批通过');
          } else if (type == 2) {
            this.$message.warning('提交被驳回');
          }
          this.$emit('approveResult');
        }
      })
    },
    // 审批结果
    approvalRecord() {
      const params = {
        homeworkUserId: this.currentWork.id
      }
      this.$api.operationHomeWork.approvalRecord({ params }).then(res => {
        if(res.data){
          this.approvalProcessList = res.data || [];
          this.approvalProcessList.forEach(item => {
            item.fileList = item.annex && JSON.parse(item.annex) || [];
          })
        }
        
        
      })
    },
    handleClose() {
      this.dialogVisible.show = false;
    },
    getHomework() {
      this.$api.operationHomeWork.getHomework(this.currentWork.homeworkId).then(res => {
        if (res.data) {
          this.ruleForm.score = res.data.maxScore;
          this.workInfo = {
            ...res.data
          }
          // 附件
          this.attachmentList = res.data.annex && JSON.parse(res.data.annex) || [];
        }
      })
    },
    getHomeWorkDetailInfo() {
      this.$api.operationHomeWork.getHomeWorkDetailInfo(this.currentWork.id).then(res => {
        if (res.data) {
          this.userInfo = {
            ...res.data
          }
          this.workList = res.data.content && JSON.parse(res.data.content) || []
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
  scrollbar-width: none;
  /* firefox */
  scrollbar-width: thin;
  crollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent;
}

.content {
  max-height: 600px;
  overflow-y: scroll;
  position: relative;
  .workStatusIcon{
    position: absolute;
    right: 0;
    top: 16px;
    width: 66px;
  }
  .line {
    height: 1px;
    background: #DFE5F1;
    border-radius: 1px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.score {
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #57667B;
    line-height: 20px;
  }

  .scoreInput {
    width: 80px;
    height: 42px;
    margin-left: 12px;
  }
}

.tip {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #AAB7C8;
  line-height: 20px;
  margin-top: 8px;
}
.uploadTip{
  margin-bottom: 16px;
}

.uploadFileBox {
  display: flex;

  .uploadBtn {
    width: 96px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid $WDPrimaryColor;
    color: $WDPrimaryColor;
    text-align: center;
    line-height: 32px;
    position: relative;
    cursor: pointer;

    span {
      margin-left: 4px;
    }
  }

  .uploadContainer {
    flex: 1;
  }
}

.title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #08224D;
  line-height: 26px;
  position: relative;
  padding-left: 8px;
  margin-bottom: 16px;

  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 14px;
    background: #1A72FF;
    left: 0;
    top: 6px;
  }
}

.userInfo {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #657D9C;
  line-height: 20px;
  margin-bottom: 8px;

  .val {
    color: #08224D;
  }

  .userCon {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    .line {
      width: 1px;
      height: 8px;
      background: #D9D9D9;
      margin: 0 16px;
    }
  }
}

.userWorkCon {
  margin-top: 16px;

  .tabs {
    display: flex;
    align-items: center;

    .tabItem {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #57667B;
      line-height: 22px;
      padding-bottom: 13px;
      margin-right: 24px;
      cursor: pointer;

      &.tabActive {
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #08224D;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 44px;
          height: 2px;
          background: #1A72FF;
          bottom: 0;
          left: 10px;
        }
      }
    }
  }
}

.workSubmitCon {
  margin-top: 16px;

  .step,
  .label {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #08224D;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .remark {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #657D9C;
    line-height: 20px;
    margin-bottom: 16px;
  }
}

::v-deep {
  .fileItem:nth-child(3n){
    margin-right: 0;
  }
  .delIcon{
    width: 16px;
  }
  .fileItem .resTitle .name {
    max-width: 135px;
  }

  .el-input__inner {
    height: 42px;
    line-height: 42px;
    padding: 0;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #08224D;
  }

  .el-textarea__inner,
  .el-input__inner {
    border-color: #A5B4CB;
    border-radius: 8px;

    ::placeholder {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #AAB7C8;
    }
  }

  .el-dialog {
    border-radius: 16px;
  }

  .el-dialog__body {
    padding: 16px 16px 0;
  }

  .el-dialog__header {
    padding: 20px 0;
    box-shadow: inset 0px -1px 0px 0px #DFE5F1;

    .el-dialog__close {
      font-size: 22px;
      font-weight: 600;
      color: #08224D;
    }

    .el-dialog__title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #08224D;
      line-height: 25px;
    }
  }
  .el-dialog__footer{
    border-top: 1px solid #DFE5F1;
    padding-top: 24px;
    .el-button{
      width: 103px;
      height: 42px;
      border-radius: 21px;
    }
  }
  
}</style>

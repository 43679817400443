<template>
  <div class="homeWorkList">
    <div class="top">
      <div class="top-left">
        <div class="tabs">
          <p class="tabItem" :class="{ tabActive: tabType === 1 }" @click="tabClick(1)">待审批</p>
          <p class="tabItem" :class="{ tabActive: tabType === 2 }" @click="tabClick(2)">已审批</p>
        </div>
      </div>
      <div class="top-right">
        <div class="search">
          <el-input v-model="name" type="text" class="input" @keyup.enter.native="getHomeWorkList(1)" placeholder="根据项目名称或提交人搜索"></el-input>
          <el-button type="primary" class="button"  @click="getHomeWorkList(1)">搜索</el-button>
        </div>
      </div>
    </div>
    <div class="WDListTable">
      <el-table :data="workList" style="width: 100%">
        <el-table-column prop="homeworkName" label="作业名称">
          <template slot-scope="{ row }">
            <p class="textOverflow" :title="row.homeworkName">{{ row.homeworkName }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="taskName" label="所属项目">
          <template slot-scope="{ row }">
            <p class="textOverflow" :title="row.taskName">{{ row.taskName }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="提交人" width="120px">
        </el-table-column>
        <el-table-column prop="submitTime" label="提交时间" width="180px">
        </el-table-column>
        <el-table-column prop="" label="操作" width="80px">
          <template slot-scope="{ row }">
            <el-button type="text" v-if="tabType === 1" @click="approvalBtn(row)">审批</el-button>
            <el-button type="text" v-if="tabType === 2" @click="approvalBtn(row)">详情</el-button>
          </template>
        </el-table-column>
        <template #empty>
          <div class="empty">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/emptyData.png" alt="">
            <p class="tip">暂无{{ tabType === 1 ? '待审批' : '已审批' }}</p>
          </div>
        </template>
      </el-table>
      
    </div>
    <div class="pagination" v-if="total">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNum"
        :page-sizes="[10, 25, 50]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <approvalDialog :type="tabType" :dialog-visible="dialogVisible" @approveResult="approveResult" :currentWork="currentWork" v-if="dialogVisible.show" />
    
  </div>
</template>

<script>
import approvalDialog from './approvalDialog.vue';
export default {
  name: 'homeWorkList',
  components:{approvalDialog},
  data() {
    return {
      dialogVisible:{
        show:false,
      },
      name: '',
      tabType: 1,
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      workList: [],
      currentWork:null,
    }
  },
  created() {
    this.getHomeWorkList(1)
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.params.pageSize = val;
      this.getHomeWorkList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.params.pageNum = val;
      this.getHomeWorkList()
    },
    approveResult(){
      this.tabType = 1;
      this.dialogVisible.show = false;
      this.getHomeWorkList(1)
    },
    approvalBtn(row){
      console.log('row',row);
      this.currentWork = row;
      this.dialogVisible.show = true;
    },
    tabClick(type) {
      this.tabType = type;
      this.getHomeWorkList(1)
    },
    getHomeWorkList(num) {
      if(num){
        this.params.pageNum = 1;
      }
      const params = { ...this.params };
      const data = {
        name: this.name,
        stu: true,
        type: this.tabType,//1 待审批 2 已审批
      }
      this.$api.operationHomeWork.getHomeWorkList({ params, data }).then(res => {
        console.log('res', res.data);
        if (res.data) {
          this.workList = res.data.list || [];
          this.total = res.data.total || 0;
        }

      })
    }
  }
}
</script>

<style lang="scss" scoped>
.empty{
  padding: 120px 0;
  img{
    width: 268px;
  }
  .tip{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    position: relative;
    bottom: 25px;
  }
}
.homeWorkList {
  padding-top: 5px;
  padding-bottom: 11px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tabs {
      display: flex;
      align-items: center;
      width: 148px;
      height: 32px;
      border-radius: 8px;
      border: 1px solid #1A72FF;

      .tabItem {
        flex: 1;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        height: 32px;
        line-height: 32px;
        cursor: pointer;

        &.tabActive {
          background-color: #1A72FF;
          font-weight: 500;
          border-radius: 6px 0px 0px 6px;
          color: #FFFFFF;

          &:nth-child(2) {
            border-radius: 0px 6px 6px 0px;
          }
        }
      }
    }

    .search {
      display: flex;
      align-items: center;

      .input {
        width: 200px;
        height: 42px;

        ::v-deep .el-input__inner {
          height: 42px;
          line-height: 42px;
          border-radius: 8px;
          border: 1px solid #A5B4CB;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #57667B;
        }
      }

      .button {
        margin-left: 8px;
        width: 60px;
        height: 42px;
        padding: 0;
        border-radius: 8px;
      }
    }
  }
  .WDListTable{
    margin-top: 8px;
    .textOverflow{
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .pagination{
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }

}

::v-deep {
  
  ::placeholder {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #57667B;
  }
}
</style>
<template>
  <div class="identify">
    <div class="headImg">
      <img class="headImage" :src="headImage ? headImage :require('../../../assets/images/userL.png')" alt="">
      <p class="sfrz">
        <img src="../../../assets/images/personal/shenfenrenzheng.png" alt="">
        <span class="text">{{isRealName? '已认证' : '未认证'}}</span>
      </p>
    </div>
    <div class="noIdentify" v-if="!isRealName && hasConfirmed">
      <div class="QRbox">
        <div id="identifyqrcode" style="width:180px;height:180px;"></div>
      </div>
      <div class="tip">扫描上方二维码进入手机端认证页面</div>
    </div>
    <div class="hasIdentified" v-else-if="hasConfirmed">
      <p class="name">{{ realName }}</p>
      <p class="idCard text1">身份证号：{{ idCard }}</p>
      <p class="time text1">认证时间：{{ idAuthTime }}</p>
      <p class="time text1">证件照片：已认证</p>
      <!-- <p class="footer"><el-button @click="dialogVisible = true">解除实名信息绑定</el-button></p> -->
    </div>
    <el-dialog custom-class="unboundDialog" title="" :visible.sync="dialogVisible" width="400px">
      <p><svg-icon icon-class="ic-ts"></svg-icon></p>
      <p class="title">提示</p>
      <div class="content">
        <p>请谨慎操作，解绑后可重新绑定新用户信息，</p>
        <p>并退出登录，是否解绑 ？</p>
      </div>
      <div class="footer">
        <el-button type="primary" @click="confirmUnbound">解除实名信息绑定</el-button>
        <el-button class="cancel" @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex'
import MD5 from 'js-md5'
import QRCode from "qrcodejs2"
export default {
  name: 'identify',
  data: () => {
    return {
      isRealName: false,
      hasConfirmed: false,
      dialogVisible: false,
      h5domain: ''
    }
  },
  computed: {
    ...mapState({
      phoneNumber: state => state.userInfo.phone,
      name: state => state.subUserInfo.name && ('*' + state.subUserInfo.name.charAt(state.subUserInfo.name.length - 1)),
      realName: state => state.subUserInfo.realName && ('*' + state.subUserInfo.realName.charAt(state.subUserInfo.realName.length - 1)),
      idCard: state => state.subUserInfo.idCardNo,
      createTime: state => state.subUserInfo.createTime,
      idAuthTime: state => state.subUserInfo.idAuthTime,
      headImage: state => state.subUserInfo.headImage,
      idAuthStatus:state => state.subUserInfo.idAuthStatus
    }),
  },
  watch: {
    isRealName(val) {
      if (val) {
        this.getUserInfoSecurity()
        clearInterval(this.timer)
      }
    }
  },
  mounted() {
    // 轮询获取实名认证状态
    this.postIsRealNameAuthentication()
    this.getAuthStaus()
  },
  methods: {
    getAuthStaus() {
      this.timer = setInterval(() => {
        this.postIsRealNameAuthentication()
      }, 2000)
    },
    // 获取个人资料 手机身份证加密
    async getUserInfoSecurity() {
      await this.$api.usercenter.getUserInfoSecurity({}).then(res => {
        if (res.data) {
          this.$store.commit('subUserInfo', Object.assign({},res.data))
          localStorage.setItem('subUserInfo', JSON.stringify(Object.assign({},res.data)))
        }
      })
    },
    postIsRealNameAuthentication() {
      this.$api.usercenter.postIsRealNameAuthentication({}).then(res => {
        const m = `${localStorage.getItem('token')}true`
        this.isRealName = res.data === MD5(m)
        // console.log('identify',this.isRealName)

        this.hasConfirmed = true//避免闪屏
        this.$nextTick(() => {
          !this.isRealName && this.qrcode()
        })
      })
    },
    async getSaasBranchDomain() {
      await this.$api.usercenter.getSaasBranchDomain(sessionStorage.getItem('branchId')).then((res) => {
        if (res.success && res.data && res.data.length) {
          let h5domain = ''
          res.data.forEach((item) => {
            if (item.branchDomain) {
              if (item.pcOrH5 === 1) {
                h5domain = item.branchDomain;
                this.h5domain = h5domain || '';
              }
            }
          })
        }
      })
      localStorage.setItem('rensheH5Domain', this.h5domain)
      return this.h5domain
    },
    async qrcode() {
      let domainArr = localStorage.getItem('domain')
      let domainUrl = domainArr && JSON.parse(domainArr).find(item => item.frontend_template_id == 4).domain
   
      if (this.QRCode) return
      this.QRCode = new QRCode('identifyqrcode', {
        width: 180,
        height: 180,        // 高度
        text: `${window.location.protocol}//${domainUrl}/login?phone=${this.phoneNumber}&redirect=/idcard`,   // 二维码内容
        display: 'none'
        // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f',   // 背景色
        // foreground: '#ff0'    // 前景色
      })
    },
    async confirmUnbound() {
      const res = await this.$api.usercenter.cancleAuth()
      if (res.success) {
        // 解绑成功，退出登录
        this.logout()
      }
    },
    async logout() {
      const params = { token: localStorage.getItem('token') }
      await this.$api.usercenter.postAccounLogout({ params })
      const branchId = sessionStorage.getItem('branchId')
      const saasId = sessionStorage.getItem('saasId')
      sessionStorage.clear()
      localStorage.clear()
      sessionStorage.setItem('branchId', branchId)
      sessionStorage.setItem('saasId', saasId)
      this.$router.push('/login');
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
/* eslint-disable no-new */
</script>

<style lang="scss" scoped>
.identify{
  text-align: center;
}
.noIdentify .QRbox,.hasIdentified .QRbox {
	width: 198px;
	height: 198px;
	border-radius: 4px;
	border: 2px solid #f0f0f0;
	margin: 0 auto 28px;
}
.noIdentify #identifyqrcode,
.hasIdentified #identifyqrcode {
	margin: 10px auto 0;
}
.noIdentify .tip,.hasIdentified .tip {
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #333;
	line-height: 28px;
}
.headImg {
	position: relative;
	.headImage {
		width: 88px;
		height: 88px;
		border-radius: 50%;
	}
	.sfrz {
		position: relative;
		bottom: 15px;
		width: 96px;
		margin: 0 auto;
		img {
			width: 96px;
		}
		.text {
			position: absolute;
			top: 10px;
			right: 15px;
			font-size: 14px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #754500;
			line-height: 14px;
			text-shadow: 0px 1px 0px rgba(255,255,255,0.5);
		}
	}
}
.name {
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #333;
	line-height: 20px;
}
.idCard,.time {
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333;
	line-height: 16px;
	margin-top: 14px;
}
.text1 {
	width: 280px;
	text-align: left;
	margin: 14px auto 0;
}

</style>
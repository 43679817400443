<template>
  <div class="AboutUs">
    <div class="top-logo">
      <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/new-saas/PC/personal/AboutUs-logo.png" class="logo" alt="">
    </div>
    <div class="content">
      <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/new-saas/PC/personal/AboutUs-img.png" alt="">
      <p>
        伟东云教育，创立于2012年，以全球互联网教育平台服务商为定位，面向各国政府、企业、院校、机构提供优质教育资源与服务，通过搭建开放型互联网教育云平台，打造“一体两翼”的全球化教育格局，努力构建惠及世界的终身教育生态。
    
      </p>
      <p>作为联合国教科文组织战略合作伙伴，伟东云教育先后承办两届四次国际教育信息化大会；作为上海合作组织战略合作伙伴，伟东云教育以务实合作落实“上海精神”，助推区域教育协同发展；作为中国教育部合作伙伴，伟东云教育努力践行“一带一路”教育行动，促进全球教育资源共建共享。
目前，伟东云教育正以职业教育为主，积极赋能新时代职业教育发展，业务已累计覆盖全国25个省、169个市区县，在全球25个国家及地区实现布局，服务众多世界500强企业。</p>
    </div>
  </div> 
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.AboutUs{
  width: 100%;
  min-height: 690px;
  height: 690px;
  background-color: #fff;  
  box-sizing: border-box;
  padding: 40px 122px;
  // overflow: auto;
  .top-logo{
    background-color: #F4F7FA;
    padding: 20px 0;
    text-align: center;  
    box-sizing: border-box;
    height: 98px;
    margin-bottom: 20px;
    .logo{
      height: 100%;  
    }
  }
  .content{
    img{
      margin-bottom: 20px;
      width: 642px;
    }
    p{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 26px;
      text-indent: 2em;
    }  
  }
}
</style>
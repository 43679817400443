<!--
 * @Author: felixli
 * @Date: 2023-11-10 14:16:08
 * @Description: 
-->
<template>
  <div class="task">
    <div class="task-box">
      <div
        class="task-box-item"
        v-for="item in taskList"
        :key="item.id"
        @click="gotoTask(item)"
        :class="{ active4n: isActive, active3n: !isActive }"
      >
        <div class="task-box-item-header">
          <div class="task-box-item-header-left">
            <img v-if="item.headImage" :src="item.headImage" alt="" />
            <img v-else src="@/assets/images/learn/nohead.png" alt="" />
            <span class="name">{{ item.creator }}</span
            ><span class="type-name">指派</span>
          </div>
          <div class="task-box-item-header-right"><p>新员工任务</p></div>
        </div>
        <div class="task-box-item-content">
          <p class="title">{{ item.name }}</p>
          <p class="date">
            {{ dateFormat(item) }}
          </p>
          <div class="percentage">
            <!-- <span>{{ item.statusStr }}</span> -->
            <el-progress
              color="$WDPrimaryColor"
              define-back-color="#E9E9E9"
              text-color="$WDPrimaryColor"
              :percentage="item.studyProcess"
            ></el-progress>
          </div>
        </div>
      </div>
    </div>
    <article v-if="noData" class="ListEmptyData">
      <img src="../../../assets/images/emptyData.png" alt />
      <p>您还没有学习内容~</p>
    </article>
    <p class="loadingmore" v-if="loading">加载中...</p>
    <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
  </div>
</template>

<script>
import $ from "jquery";
import dayjs from "dayjs";
export default {
  name: "studyTask",
  props: {
    pageNums: {
      type: Number,
      default: 9,
    },
  },
  components: {},
  data() {
    return {
      studyProcess: 75,
      taskList: [],
      paging: {
        params: {
          pageNum: 1,
          pageSize: this.pageNums,
        },
        total: 0,
      },
      loading: false,
      noData: false,
      isActive: false,
    };
  },
  watch: {},
  computed: {
    noMore() {
      return (
        this.taskList.length === this.paging.total && this.paging.total !== 0
      );
    },
  },
  created() {
    console.log(this.$route.path);
    if (this.$route.path === "/learn/list") this.isActive = true;
    this.getTaskList();
  },
  mounted() {
    $(window).scroll(() => {
      if (
        Math.round($(window).scrollTop()) ===
        $(document).height() - $(window).height()
      ) {
        setTimeout(this.reachBottom(), 1000);
      }
    });
  },
  methods: {
    async getTaskList() {
      let params = {
        pageNum: this.paging.params.pageNum,
        pageSize: this.paging.params.pageSize,
        taskStatus: "",
      };
      const { code, data } = await this.$api.learn.taskOpenList({ params });
      if (code === 0) {
        let ids = [];
        if (data?.list?.length) {
          data.list.forEach((item) => {
            ids.push(item.id);
          });
          this.noData = false;
        } else {
          this.noData = true;
        }
        if (this.paging.params.pageNum > 1) {
          this.taskList = this.taskList.concat(data.list);
        } else {
          this.taskList = data.list || [];
        }
        await this.findTaskLearningInfo(ids.join(","));
        this.paging.total = data?.total;
        // this.paging.params.pageNum = data?.pageNum;
        // this.paging.params.pageSize = data?.pageSize;
      }
    },
    // 二次渲染 学习相关信息
    async findTaskLearningInfo(ids) {
      let params = {
        param: ids,
      };
      await this.$api.learn.findTaskLearningInfoTask({ params }).then((res) => {
        if (res.data) {
          this.taskList.forEach((item) => {
            for (let key in res.data) {
              if (Number(key) === item.id) {
                item.contentTypeSet = res.data[key].contentTypeSet;
                item.studyProcess = res.data[key].studyProcess;
                // item.courseItemTypeList = res.data[key].courseItemTypeList.filter((item) => { return item.type !== 2 });
                item.courseItemTypeList = res.data[key].courseItemTypeList;
                item.courseNum = res.data[key].courseNum;
                item.learnTotalTime = res.data[key].learnTotalTime;
                item.startTime = res.data[key].startTime ?? item.startTime;
                item.endTime = res.data[key].endTime ?? item.endTime;
                // console.log(item.courseItemTypeList, 'item.courseItemTypeList')
              }
            }
          });
        }
      });
    },
    reachBottom() {
      if (
        this.paging.total >
        this.paging.params.pageNum * this.paging.params.pageSize
      ) {
        this.paging.params.pageNum = this.paging.params.pageNum + 1;
        this.loading = true;
        this.getTaskList("reachBottom");
      } else {
        this.loading = false;
      }
    },
    gotoTask({ id, studyStatus }) {
      if (studyStatus === 1) {
        this.$message.warning({
          message: "还未到任务学习时间",
          offset: 70,
        });
        return false;
      } else if (studyStatus === 3) {
        this.$message.warning({
          message: "该任务已到期",
          offset: 70,
        });
        return false;
      }
      this.$router.push(`/learn/task/detail?id=${id}`);
      //this.$router.push(`/learn/task/detail?id=${id}&source=studyTask`);//2023-12-11-优化-素材/课程单独播放:&source=studyTask
    },
    dateFormat(obj) {
      return (
        (obj?.startTime
          ? dayjs(obj?.startTime).format("YYYY.MM.DD HH:mm")
          : "") +
        "-" +
        (obj?.endTime ? dayjs(obj?.endTime).format("YYYY.MM.DD HH:mm") : "")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.task-box {
  display: flex;
  flex-wrap: wrap;
  &-item {
    width: 258px;
    height: 165px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(34, 98, 188, 0.1);
    border-radius: 8px;
    margin-right: 40px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    &.active3n {
      margin-right: 32px;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    &.active4n:nth-child(4n) {
      margin-right: 0;
    }
    &-header {
      width: 100%;
      height: 54px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border: 1px solid #f4f7fa;
      &-left {
        flex: 1;
        display: flex;
        align-items: center;
        padding-left: 16px;
        font-weight: 400;

        img {
          width: 26px;
          height: 26px;
          border-radius: 50%;
        }
        > .name {
          max-width: 85px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #333333;
          padding-left: 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        > .type-name {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #666666;
          padding-left: 4px;
        }
      }
      &-right {
        width: 86px;
        display: flex;
        justify-content: flex-end;
        > p {
          width: 100%;
          height: 26px;
          background: #f2a73b;
          box-shadow: 0px 4px 16px 0px rgba(34, 98, 188, 0.1);
          border-radius: 100px 0 0 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          color: #ffffff;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
        }
      }
    }
    &-content {
      flex: 1;
      width: 100%;
      padding: 5px 8px 0 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      .title {
        width: 192px;
        height: 26px;
        line-height: 28px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .date {
        height: 24px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #08224d;
        overflow: hidden;
      }
      .percentage {
        height: 45px;
        display: flex;
        align-items: center;
        span {
          font-size: 12px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #333333;
          padding-right: 8px;
        }
        .el-progress {
          flex: 1;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
::v-deep {
  .el-progress {
    display: flex;
    align-items: center;
    .el-progress-bar {
      margin-right: 0;
      padding-right: 14px;
    }
    .el-progress-bar__outer {
      // width: 372px;
      height: 10px !important;
    }
    .el-progress__text {
      margin-left: 0;
      margin-right: 10px;
    }
  }
}
</style>

<template>
  <div class="approvalManagement">
    <template v-if="!currentId">
      <div class="approvalManagementList">
        <div class="item" @click="jump(item)" v-for="(item, index) in list" :key="index">
          <img :src="item.icon" alt="">
          <p class="name">{{ item.name }}</p>
          <div class="right">
            <p class="num" :class="{ num2: item.num >= 10 }">{{ item.num > 99 ? 99 + '+' : item.num }}</p>
            <svg-icon icon-class="arrow-right"></svg-icon>
          </div>
        </div>
      </div>
    </template>
    <!-- 作业列表 -->
    <template v-if="currentId === 1">
      <homeWorkList />
    </template>
  </div>
</template>

<script>
import homeWorkList from '../components/homeWorkList.vue';
export default {
  name: 'approvalManagement',
  components: {
    homeWorkList
  },
  props: {
    homeworkNum: {
      type: Number,
      default: 0,
    },
    currentId: {
      type: Number,
      default: 0,
    }
  },
  watch:{

  },
  data() {
    return {
      
      list: [
        {
          id: 1,
          icon: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/homeWorkIcon.png',
          name: '实操作业审批',
          num: this.homeworkNum
        }
      ]
    }
  },
  created(){
    console.log('=============');
    
  },
  methods:{
    jump(item){
      this.$emit('approvalJump',item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.approvalManagement {
  background: #FFFFFF;
  border-radius: 0px 0px 16px 16px;
  min-height: 371px;
  box-sizing: border-box;
  padding: 16px;

  .item {
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border: 1px solid #F5F5F5;
    padding: 18px 16px;
    cursor: pointer;

    img {
      width: 16px;
      margin-right: 4px;
    }

    .name {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }

    .right {
      margin-left: auto;
      display: flex;
      align-items: center;

      .num {
        margin-right: 4px;
        min-width: 16px;
        height: 16px;
        background: #FE5746;
        border-radius: 50%;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        text-align: center;
        line-height: 16px;
        padding: 0;

        &.num2 {
          padding: 0 6px;
          font-size: 12px;
          border-radius: 8px;
        }
      }

      .icon {
        color: #999;
        font-size: 16px;
      }
    }
  }
}
</style>
<template>
  <div class="certificateList">
    <div class="label">我的证书（{{ total }}）</div>
    <div class="contentList">
      <div class="item" v-for="item in certificateList" @click="checkCertificate(item)" :key="item.id">
        <div class="imgUrl">
          <img :src="item.url" alt="" v-if="item.url">
          <img src="../../../assets/images/certificate/certificate-place.png" v-else alt="">
        </div>
        <div class="bottom">
          <p class="name" :title="item.name">{{ item.name }}</p>
          <p class="time">获证时间：{{ item.certifiedTime }}</p>
        </div>
      </div>
    </div>
    <auth-guide :ModalVisbileAuth="authGuideModalVisbile" @handleCloseAuth="handleCloseAuth" :wechatErInfo="previewInfoImg"></auth-guide>
    <previewCertificate v-if="previewModalVisbile.show" @downLoadCer="downLoadCer" :previewModalVisbile="previewModalVisbile" :isDownLoad="isDownLoad" :previewUrl="previewUrl" :currentName="currentName"></previewCertificate>
    <article v-if="noData" class="ListEmptyData">
      <img src="../../../assets/images/emptyData.png" alt />
      <p>暂无数据</p>
    </article>
    <el-dialog
        class="tip-dialog"
        title=""
        :visible.sync="dialogVisible"
        width="400px">
        <div>
            <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
            <p class="titles">提示</p>
            <p class="text">请先到个人资料页中完善信息</p>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="info" @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="toPersonal">完善资料</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentInfo:null,
      isDownLoad:true,
      currentName:'',
      total:0,
      noData: null,
      dialogVisible:false,
      previewModalVisbile: {
        show:false
      },
      // 实名弹窗
      authGuideModalVisbile: false,
      previewUrl: '',
      previewInfoImg: {
        name: '',
        userName: '',
      },
      certificateList: []
    }
  },
  created(){
    this.getData()
  },
  methods: {
    toPersonal(){
        if(this.$route.path === '/personal'){
            this.$emit('changeTab','我的资料')
        }else {
            sessionStorage.setItem('userCenterCurrentTab','我的资料')
            this.$router.push('/personal')
        }
    },
    getData() {
      this.$api.certificate.getCertificateList().then(res => {
        if(res.data && res.data.length){
          this.noData = false
        }else {
          this.noData = true
        }
        this.certificateList = res.data || [];
        this.total = res.data ? res.data.length : 0;
        
      })
    },
    // 是否需要实名认证 
    // 训练营里面，我的证书只传参数businessId, 我的证书列表里面，点击证书详情，只需要传id
    // 判断 isAuthentication  userAuthenStatus  ,
    // 1.isAuthentication 为0代表证书不需要实名 直接展示证书
    // 2.isAuthentication 为1代表证书 需要实名，
    // 判断 userAuthenStatus  是0  代表用户未实名，此时需要让用户去实名
    // userAuthenStatus是1代表用户已经实名 直接展示证书
    checkCertificate(item) {
      this.$api.certificate.getCertificateWithCheck('id', item.id).then(res => {
        if(res.code==50005){
          this.$message({
            type: 'warning',
            message: '请联系管理员完善相关信息！',
            duration: 2000
          })
          return
        }else{
          if (res.data) {
          this.previewInfoImg.name = res.data.businessName;
          this.previewInfoImg.userName = res.data.userName;
          this.previewInfoImg.phone = res.data.phone;
          this.previewUrl = res.data.url;
          if (res.data.isAuthentication == 0 || (res.data.isAuthentication == 1 && res.data.userAuthenStatus == 1)) { //不需要实名或者需要实名并且已实名
            if (!res.data.url) {
              this.dialogVisible = true //需要用户去完善资料
              return
            }
            this.currentName = item.name;
            this.currentInfo = item;
            this.previewModalVisbile.show = true;
        
            this.authGuideModalVisbile = false;
          } else {
            this.previewModalVisbile.show = false;
            this.authGuideModalVisbile = true;
          }
        } else {
          this.$message({
            type: 'warning',
            message: '你还没有获得证书，请继续努力！',
            duration: 2000
          })
        }
        }


      })
    },
    handleCloseAuth() {
      this.authGuideModalVisbile = false;
    },
    handleClosePreview() {
      this.previewModalVisbile = false;
    },
    downLoadCer() {
      let x = new XMLHttpRequest();
      x.open("GET", this.previewUrl, true);
      x.setRequestHeader('Cache-Control', 'no-cache');
      x.responseType = 'blob';
      x.onload = () => {
        let url = window.URL.createObjectURL(x.response)
        let a = document.createElement('a');
        a.href = url;
        a.download = this.currentInfo.userName + '-' + this.currentInfo.name + '.jpg';
        a.click()
      }
      x.send();
    }
  }
}
</script>

<style lang="scss" src="../asset/css/certificateList.scss" scoped>

</style>
<style lang="scss" scoped>
.tip-dialog {
	text-align: center;
	::v-deep {
		.el-dialog {
			border-radius: 10px;
			.el-dialog__header {
				padding: 0;
				.el-dialog__headerbtn {
					.el-dialog__close {
						font-size: 26px;
					}
				}
			}
			.el-dialog__body {
				padding-top: 40px;
				padding-bottom: 30px;
				.icon-img {
					font-size: 50px;
					margin: 0 auto;
				}
				.titles {
					font-size: 24px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #1b2257;
					line-height: 34px;
					margin: 15px 0;
				}
				.text {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #666;
					line-height: 24px;
					word-break: break-word;
				}
			}
			.el-dialog__footer {
				padding: 0 36px 30px;
				.dialog-footer {
					display: flex;
					justify-content: center;
					.el-button {
						height: 40px;
						text-align: center;
						&.el-button--default {
							span {
								color: #666;
							}
						}
					}
				}
			}
		}
	}
}

</style>
